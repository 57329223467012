import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import { documentToReactComponents as mapRichText } from '@contentful/rich-text-react-renderer'
import _get from 'lodash.get'

import Header from '../components/Header'
import Hero from '../components/Hero'
import Typography from '../components/Typography'
import PackageProvider from '../components/PackageProvider'
import Footer from '../components/Footer'

import determineSection from '../utils/determineSection'
import { richTextLegalMap, richTextHeroMap } from '../utils/richTextBlockMap'

const LandingPage = ({ data }) => {
  const {
    seoTitle,
    seoDescription,
    seoCanonical,
    hero,
    sections,
  } = data.contentfulPage
  return (
    <>
      <Helmet
        htmlAttributes={{ lang: 'en-US' }}
        title={seoTitle}
        link={
          seoCanonical
            ? [{ rel: 'canonical', key: seoCanonical, href: seoCanonical }]
            : []
        }
        meta={[
          {
            name: 'description',
            content: seoDescription,
          },
        ]}
      />
      <Header
        topBarNavItems={[{ title: 'Clearlink Business', url: '/' }]}
        headerNavItems={[
          {
            title: 'FiOS',
            subnav: [
              { title: 'Clearlink FiOs', url: '/' },
              { title: 'What is FiOs?', url: '/' },
              { title: 'FiOs vs Cable', url: '/' },
            ],
          },
          {
            title: 'Internet',
            url: '/BillboardPage',
            subnav: [
              { title: 'High Speed Internet', url: '/' },
              { title: 'Clearlink Internet', url: '/' },
              { title: 'How Do I Internet?', url: '/' },
              { title: 'What IS Internet?', url: '/' },
              { title: 'Cuantos Internets?', url: '/' },
            ],
          },
          { title: 'TV', url: '/TabsPage' },
          { title: 'Phone', url: '/' },
          { title: 'Plans & Pricing', url: '/' },
          { title: 'More', url: 'https://github.com' },
        ]}
      />
      {hero && (
        <Hero
          type={hero.type}
          image={{
            childImageSharp: _get(hero, 'image.localFile.childImageSharp'),
            description: _get(hero, 'image.description'),
            fields: _get(hero, 'image.localFile.fields'),
          }}
          darkText={hero.textColor === 'Dark'}
        >
          <Typography variant="h1">{hero.heading}</Typography>
          {hero.subheading && (
            <Typography variant="h4" rendersAs="h2">
              {hero.subheading}
            </Typography>
          )}
          {hero.module &&
            (hero.module.__typename === 'ContentfulPackage' && (
              <PackageProvider
                price={{
                  amount: hero.module.price,
                }}
                legal={
                  hero.module.disclaimer &&
                  mapRichText(
                    hero.module.disclaimer.mainContent.json,
                    richTextLegalMap
                  )
                }
                bullets={mapRichText(hero.module.bullets.json, richTextHeroMap)}
              />
            ))}
          {hero.mainContent &&
            mapRichText(hero.mainContent.json, richTextHeroMap)}
        </Hero>
      )}

      <main>
        {sections && sections.map(section => determineSection(section))}
      </main>

      <Footer footerNavItems={[]} disclaimers={[]} />
    </>
  )
}

LandingPage.propTypes = {
  data: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
}

export default LandingPage

export const query = graphql`
  query LandingPageQuery($id: String!) {
    contentfulPage(id: { eq: $id }) {
      ...LeshenPage
    }
  }
`
